import React from "react"
import scrollTo from 'gatsby-plugin-smoothscroll';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Subscribe from "../components/subscribe"

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="banner">
      <div className="container">
        <div className="row">
          <div className="banner-wrapper">
            <div id="index-banner" className="col s12 m12 l6">
              <div className="bannertext">
                <h1>Furrble,</h1>
                <br />
                <h2>Making Pet Parenting <br />Informed, Easy and Fun.</h2>
                <button id="learn-more-btn" className="pet-orange btn" onClick={() => scrollTo('#furrfit-section')}>Tell me more</button>
              </div>
            </div>

            <div id="index-banner2" className="col s12 m12 l6 bannerimg">
              <img alt="" src="/images/bannerimg.png"></img>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="services" className="content">
      <div className="container">

        <div id="furrfit-section" className="service row">
          <div className="section-wrapper flex-dir-col-rev">
            <div className="section-l">
              <div className="service-desc">
                <h2 className="section-head">Furr<span className="pet-orange-text">Fit</span></h2>
                <div className="section-content">
                  <ul className="fa-ul">
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      FurrFit the saviour helps in early detection of your pet’s unusual activities and behaviour!</p>
                    </li>
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      Constantly worried about losing your Furry Friend? Not anymore with the live GPS tracking feature of FurrFit.</p>
                    </li>
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      Stay carefree, as a single charge provides upto 20 days of battery.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="section-r">
                <img src="/images/furryfit.png" alt="FurrFit"></img>
            </div>
          </div>
        </div>

        <div className="service row">
          <div className="section-wrapper">
            <div className="section-l">
              <img alt="" src="/images/custommeal.png"></img>
            </div>
            <div className="section-r">
              <div className="service-desc">
                <h2 className="section-head">Customized <span className="pet-orange-text">Meal</span></h2>
                <div className="section-content">
                  <ul className="fa-ul">
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      Your furr-friends will relish our nutritious meals, custom-made for them to suit their needs.</p>
                    </li>
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      With regular delivery at your doorstep, you’ll never run out of your pet’s favorite food.</p>
                    </li>
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      Only human grade ingredients are used to prepare your furr’s food!</p>
                    </li>
                  </ul>
                </div>                
              </div>
            </div>   
          </div>
        </div>

        {/* <div className="service row">
          <div className="section-wrapper flex-dir-col-rev">
            <div className="section-l">
              <div className="service-desc">
                <h2 className="section-head">Intelligent <span className="pet-orange-text">Healthcare</span></h2>
                <div className="section-content">
                  <ul className="fa-ul">
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      Monthly health reports, generated via Artificial Intelligence, makes your best-furrend’s fitness tracking easy.</p>
                    </li>
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      Enjoy regular, scheduled vet visits, in the comfort of your home.</p>
                    </li>
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      Forget about your pet’s vaccine schedule - our Intelligent health monitoring system has it all covered.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="section-r">
              <img src="/images/intelligenthealthcare.png" alt="Intelligent Healthcare"></img>
            </div>   
          </div>
        </div>

        <div className="service row">
          <div className="section-wrapper">
            <div className="section-l">
              <img src="/images/grooming.png" alt="Pet Grooming"></img>
            </div>
            <div className="section-r">
              <div className="service-desc">
                <h2 className="section-head">Style it like <span className="pet-orange-text">a star</span></h2>
                <div className="section-content">
                  <ul className="fa-ul">
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      Other furries will whistle and stare, seeing your Furry Friend’s pawfect hair.</p>
                    </li>
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      Be relaxed, pet parents! While we clean, we follow utmost hygiene.</p>
                    </li>
                    <li>
                      <p>
                      <span className="fa-li"><FontAwesomeIcon icon={[`fas`, `paw`]} color="#f7a11c" size="1x" /></span>
                      Relax and enjoy our pawsome services right at your doorstep as well as the salon of your choice.</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>   
          </div>
        </div> */}

      </div>
    </div>


    <Subscribe />

  </Layout>
)

export default IndexPage
