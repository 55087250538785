import React from "react"

const Subscribe = () => (
    <div id="subscribe" className="subscribe content">
        <div className="container">
            <div className="row">
                <div className="subscribe-wrapper">
                    <div className="newsletter">
                        <h2 className="section-head">Join <span className="pet-orange-text">Us</span></h2>
                        <p>Just one Newsletter every month. Paw-mise we won’t spam you!</p>
                        <div id="mc_embed_signup">
                            <form className="subscribe-form" action="https://furrble.us7.list-manage.com/subscribe/post?u=7f9dc28cc5061e5b17f436c03&amp;id=39a76ccf69" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form">
                                <div className="input-field">
                                    <input id="mce-EMAIL" type="email" name="EMAIL" placeholder="Email Address" className="email validate" required />
                                    {/* <label for="email">Email</label> */}
                                    <div aria-hidden="true"
                                        style={{
                                            display: `none`
                                        }}>
                                        <input type="text" name="b_7f9dc28cc5061e5b17f436c03_39a76ccf69" tabindex="-1" value="" />
                                    </div>
                                </div>
                                <button id="mc-embedded-subscribe" className="btn waves-effect waves-light pet-orange" type="submit" name="subscribe">Subscribe</button>
                            </form>
                        </div>
                        <p>Be a part of our paw-some fam.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Subscribe;
